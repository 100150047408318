import React, {useState} from 'react';
import TaskRefundUserTopUpSri from "./ws/TaskRefundUserTopUpSri";
import {ActionConfirmButton} from "./components/ActionConfirmButton";
import {LoadingView, ButtonClasses} from "@cuatroochenta/co-admin-react-library";

interface IProps {
    usertopupsri: any
}

export const RefundUserTopUpSriButtonViewScreen = (
    {
        usertopupsri,
    }: IProps
): React.ReactElement => {
    const [isActionLoading, setIsActionLoading] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);



    const refundUserTopUpSri = () => {
        setIsActionLoading(true);
        new TaskRefundUserTopUpSri({'oid': usertopupsri.oid, 'isRefund': usertopupsri.refund})
            .onSuccess(() => {
                    window.location.reload()
                }
            )
            .onFail((error: any) => {
                setErrorMessage(error);
                setIsActionLoading(false);
            })
            .execute();
    }


    const renderActionButtons = (): React.ReactElement | null => {
        return (
            <ActionConfirmButton
                disabled={usertopupsri.refund}
                title={"Devolver compra"}
                message={"¿Está seguro que desea devolver esta recarga de usuario?"}
                doActionOnConfirm={() => refundUserTopUpSri()}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_BORDER}
            />
        );
    }

    return (
        <div className="content-state-action-intercom">
            <LoadingView loading={isActionLoading} />
            {errorMessage && <div className="alert alert-danger fade show">{errorMessage}</div>}
            <div className="state-description" />
            { renderActionButtons() }
        </div>
    )
}
