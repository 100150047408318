import React, {useState} from 'react';
import {Button, ButtonClasses, Modal} from '@cuatroochenta/co-admin-react-library';

interface IProps {
    title: string
    message: string
    doActionOnConfirm: () => void
    disabled?: boolean,
    buttonClass?: ButtonClasses,
}

export const ActionConfirmButton = (
    {
        title,
        message,
        doActionOnConfirm,
        disabled = false,
        buttonClass,
    }: IProps
): React.ReactElement => {
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false)

    const handleAccept = () => {
        setIsModalOpened(false);
        doActionOnConfirm();
    }

    const handleClose = () => {
        setIsModalOpened(false);
    }
    if (disabled) {
        return <></>;
    }

    return (
        <>
            <Button
                content={title}
                onClick={() => setIsModalOpened(true)}
                buttonClass={buttonClass}
            />
            <Modal
                show={isModalOpened}
                title={title}
                acceptHandler={handleAccept}
                onClose={handleClose}
                confirmationModal={true}
            >
                <div>{message}</div>
            </Modal>
        </>
    )
}
