import React, {useState} from 'react';
import TaskRefundChargeHistory from "./ws/TaskRefundChargeHistory";
import {ActionConfirmButton} from "./components/ActionConfirmButton";
import {LoadingView, ButtonClasses} from "@cuatroochenta/co-admin-react-library";

interface IProps {
    chargehistory: any
}

export const CustomButtonViewScreen = (
    {
        chargehistory,
    }: IProps
): React.ReactElement => {
    const [isActionLoading, setIsActionLoading] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);



    const refundChargeHistory = () => {
        setIsActionLoading(true);
        new TaskRefundChargeHistory({'oid': chargehistory.oid, 'isRefund': chargehistory.refund})
            .onSuccess(() => {
                    window.location.reload()
                }
            )
            .onFail((error: any) => {
                setErrorMessage(error);
                setIsActionLoading(false);
            })
            .execute();
    }


    const renderActionButtons = (): React.ReactElement | null => {
        console.log(chargehistory);
        console.log(chargehistory.refund);
        return (
            <ActionConfirmButton
                disabled={chargehistory.refund}
                title={"Devolver compra"}
                message={"¿Está seguro que desea devolver esta recarga?"}
                doActionOnConfirm={() => refundChargeHistory()}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_BORDER}
            />
        );
    }

    return (
        <div className="content-state-action-intercom">
            <LoadingView loading={isActionLoading} />
            {errorMessage && <div className="alert alert-danger fade show">{errorMessage}</div>}
            <div className="state-description" />
            { renderActionButtons() }
        </div>
    )
}
