import { BaseGenericRequest, getUrlEndpointPrivate } from "@cuatroochenta/co-admin-react-library";
import {METHOD} from "@cuatroochenta/co-generic-request";

interface TaskRefundChargeHistoryResponse {
    success: boolean,
    message: string
}

export default class TaskRefundChargeHistory extends BaseGenericRequest<{}, TaskRefundChargeHistoryResponse> {

    private readonly data;

    public constructor(data: { oid: string, isRefund: boolean }) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('chargehistory/cancel/' + data.oid));
        this.data = data;
    }

    protected getRequest(): {} {
        return this.data;
    }
}